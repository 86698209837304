<template>
  <div id="CartComp">
    <div class="cc_container">
      <div class="text_box">
        <p class="p1">🎉 주문이 완료되었습니다! 🎉</p>
        <p class="p1">소중한 주문에 진심으로 감사드립니다. 🙏</p>
        <p class="p2">
          배송 일정 및 기타 안내 사항은 입력하신 휴대폰 번호로 문자 메시지를 통해 보내드릴 예정입니다. <br>
          추가 문의 사항이 있으시면 언제든지 고객센터를 이용해 주세요.
        </p>
        <p class="p3">
          📞 문의 방법: <br />
          고객센터 → 1:1 문의 → 문의하기
        </p>
        <p class="p4">앞으로도 좋은 상품과 서비스로 보답하겠습니다. 감사합니다! 😊</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CartComp"
  }
</script>